<template>
  <b-card>
    <b-row class="mb-2 mx-3 justify-content-between">
      <h4 class="text-black" style="font-size: 24px!important">
        <strong> SLA Monitoring </strong>
      </h4>

      <button
        class="btn d-flex align-items-center"
        type="button"
        id="filter-date"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style="border: 1px solid #ECE9F1; border-radius: 4px;"
      >
        {{ titleDate }} {{ yearApply }}

        <b-img class="ml-50" src="https://storage.googleapis.com/komerce/assets/icons/arrow-down-light.svg" />
      </button>
      <b-popover target="filter-date" triggers="hover" placement="bottom">
        <div
          class="d-flex align-items-center justify-content-between px-50 mb-1"
        >
          <b-button
            class="btn-icon"
            variant="flat-dark"
            size="sm"
            @click="decrement($event)"
          >
            <b-img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-left-2.svg"
            />
          </b-button>
          <div class="mx-5">
            <span class="text-black">
              <strong>
                {{ yearDateRange }}
              </strong>
            </span>
          </div>
          <b-button
            :class="yearDateRange < liveYear ? 'btn-icon' : 'btn-icon cursor-not-allowed'"
            variant="flat-dark"
            size="sm"
            :disabled="yearDateRange >= liveYear"
            @click="increment($event)"
          >
            <b-img
              src="https://storage.googleapis.com/komerce/assets/icons/arrow-right-2.svg"
            />
          </b-button>
        </div>

        <b-row>
          <b-col
            v-for="(item, index) in listDateFilter"
            :key="index + 1"
            cols="4"
            class="mb-1"
          >
            <div :class="getClassMonth(item)" @click="setMonth(item)">
              <strong>
                {{ item.label }}
              </strong>
            </div>
          </b-col>
        </b-row>
      </b-popover>
    </b-row>

    <b-row>
      <b-col>
        <div class="table-responsive" style="min-height: 250px!important">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td class="title-table" scope="col" colspan="1">
                  <span class="text-black">
                    <strong> Ekspedisi </strong>
                  </span>
                </td>
                <td class="title-table" scope="col" colspan="3">
                  <div class="d-flex align-items-center justify-content-center">
                    <span class="text-black mr-1">
                      <strong> Percobaan Antar </strong>
                    </span>
                    <b-img
                      id="delivery-attempt"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                    />
                    <b-popover
                      triggers="hover"
                      target="delivery-attempt"
                      placement="topleft"
                      custom-class="custom-popover"
                    >
                      Jumlah percobaan pengantaran sampai dengan diputuskan retur
                    </b-popover>
                  </div>
                </td>
                <td class="title-table" scope="col" colspan="3">
                  <div class="d-flex align-items-center justify-content-center">
                    <span class="text-black mr-1">
                      <strong> Durasi Hold </strong>
                    </span>
                    <b-img
                      id="hold-duration"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                    />
                    <b-popover
                      triggers="hover"
                      target="hold-duration"
                      placement="topleft"
                      custom-class="custom-popover-hold"
                    >
                      Jumlah hari paket ditahan sejak pengantaran pertama sampai dengan diputuskan retur
                    </b-popover>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="title-table" scope="col">
                  <span class="text-black"> Nama Ekspedisi </span>
                </td>
                <td class="title-table" scope="col">
                  <span class="text-black"> Rata-rata </span>
                </td>
                <td class="title-table" scope="col">
                  <span class="text-black"> 1x </span>
                </td>
                <td class="title-table" scope="col">
                  <span class="text-black"> 2x </span>
                </td>
                <td class="title-table" scope="col">
                  <span class="text-black"> Rata-rata </span>
                </td>
                <td class="title-table" scope="col">
                  <div class="d-flex align-items-center">
                    <div v-text="'<'"></div>
                    <span class="text-black"> 3 hari </span>
                  </div>
                </td>
                <td class="title-table" scope="col">
                  <span class="text-black"> 3-6 hari </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in slaItems" :key="index + 1">
                <td>
                  <b-img
                    v-if="item.shipping === 'JNE'"
                    src="https://storage.googleapis.com/komerce/assets/logo/jne.svg"
                    width="60"
                  />
                  <b-img
                    v-if="item.shipping === 'SICEPAT'"
                    src="https://storage.googleapis.com/komerce/assets/logo/sicepat.svg"
                    width="60"
                  />
                  <b-img
                    v-if="item.shipping === 'IDEXPRESS'"
                    src="https://storage.googleapis.com/komerce/assets/logo/idexpress.svg"
                    width="60"
                  />
                  <b-img
                    v-if="item.shipping === 'SAP'"
                    src="https://storage.googleapis.com/komerce/assets/svg/sap.svg"
                    style="height: 25px"
                  />
                  <b-img
                    v-if="item.shipping === 'NINJA'"
                    src="https://storage.googleapis.com/komerce/assets/LP-Komship/Ninja.svg"
                    width="60"
                  />
                  <b-img
                    v-if="item.shipping === 'J&T'"
                    src="https://storage.googleapis.com/komerce/assets/LP-Komship/JNT.svg"
                    width="60"
                  />
                </td>
                <td>
                  <span class="text-black">
                    {{ item.delivery_attempt_avg }}x
                  </span>
                </td>
                <td>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span class="text-black">
                      {{ item.delivery_attempt_1_times }} ({{
                        item.delivery_attempt_1_ptg
                      }}%)
                    </span>
                    <div class="cursor-pointer" @click="exportData('delivery_attempt_1', item.shipping)">
                      <b-img
                        src="https://storage.googleapis.com/komerce/assets/svg/document-download.svg"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span class="text-black">
                      {{ item.delivery_attempt_2_times }} ({{
                        item.delivery_attempt_2_ptg
                      }}%)
                    </span>
                    <div class="cursor-pointer" @click="exportData('delivery_attempt_2', item.shipping)">
                      <b-img
                        src="https://storage.googleapis.com/komerce/assets/svg/document-download.svg"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <span class="text-black">
                    {{ item.hold_duration_avg }} hari
                  </span>
                </td>
                <td>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span class="text-black">
                      {{ item.hold_less_3_day }} ({{
                        item.hold_less_3_day_ptg
                      }}%)
                    </span>
                    <div class="cursor-pointer" @click="exportData('hold_less_3', item.shipping)">
                      <b-img
                        src="https://storage.googleapis.com/komerce/assets/svg/document-download.svg"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span class="text-black">
                      {{ item.hold_3_to_6_day }} ({{
                        item.hold_3_to_6_day_ptg
                      }}%)
                    </span>
                    <div class="cursor-pointer" @click="exportData('hold_3_6', item.shipping)">
                      <b-img
                        src="https://storage.googleapis.com/komerce/assets/svg/document-download.svg"
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-if="isNoData">
                <span class="text-black no-data-class">
                  Tidak ada data untuk ditamplikan.
                </span>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      yearDateRange: new Date().getFullYear(),
      liveYear: new Date().getFullYear(),
      yearActive: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      liveMonth: new Date().getMonth(),

      titleDate: 'Januari',

      listDateFilter: [
        {
          title: 'Januari',
          label: 'Jan',
          value: 0,
        },
        {
          title: 'Februari',
          label: 'Feb',
          value: 1,
        },
        {
          title: 'Maret',
          label: 'Mar',
          value: 2,
        },
        {
          title: 'April',
          label: 'Apr',
          value: 3,
        },
        {
          title: 'Mei',
          label: 'Mei',
          value: 4,
        },
        {
          title: 'Juni',
          label: 'Jun',
          value: 5,
        },
        {
          title: 'Juli',
          label: 'Jul',
          value: 6,
        },
        {
          title: 'Agustus',
          label: 'Agu',
          value: 7,
        },
        {
          title: 'September',
          label: 'Sep',
          value: 8,
        },
        {
          title: 'Oktober',
          label: 'Okt',
          value: 9,
        },
        {
          title: 'November',
          label: 'Nov',
          value: 10,
        },
        {
          title: 'Desember',
          label: 'Des',
          value: 11,
        },
      ],
      payloadSla: {
        startDate: '2023-01-01',
        endDate: '2023-01-31',
      },
      yearApply: new Date().getFullYear(),
      isNoData: false,
    }
  },
  computed: {
    ...mapState('slaMonitoring', ['slaItems']),
  },
  mounted() {
    const findCurrentMonth = this.listDateFilter.find(item => item.value === new Date().getMonth())
    this.titleDate = findCurrentMonth.title
    this.getDaysInMonth(findCurrentMonth.value, this.yearDateRange)
  },
  methods: {
    getClassMonth(date) {
      let classValue = 'button-month-past d-flex justify-content-center align-items-center cursor-pointer'
      if (this.yearDateRange === this.liveYear) {
        if (
          date.value === this.currentMonth && this.yearActive === this.yearDateRange
        ) {
          classValue = 'button-month-active d-flex justify-content-center align-items-center cursor-pointer'
        } else if (date.value <= this.liveMonth) {
          classValue = 'button-month-past d-flex justify-content-center align-items-center cursor-pointer'
        } else if (date.value > this.liveMonth) {
          classValue = 'button-month-disabled d-flex justify-content-center align-items-center cursor-not-allowed'
        }
      }
      if (this.yearActive === this.yearDateRange) {
        if (date.value === this.currentMonth) {
          classValue = 'button-month-active d-flex justify-content-center align-items-center cursor-pointer'
        }
      }
      return classValue
    },
    setMonth(data) {
      if (this.yearDateRange === this.liveYear) {
        if (data.value <= this.liveMonth) {
          this.currentMonth = data.value
          this.titleDate = data.title
          this.yearActive = this.yearDateRange
          this.getDaysInMonth(data.value, this.yearDateRange)
        }
      } else {
        this.currentMonth = data.value
        this.titleDate = data.title
        this.yearActive = this.yearDateRange
        this.getDaysInMonth(data.value, this.yearDateRange)
      }
      this.yearApply = this.yearDateRange
    },
    increment($event) {
      this.yearDateRange += 1
    },
    decrement($event) {
      this.yearDateRange -= 1
    },
    fetchData() {
      this.$store.dispatch('slaMonitoring/FetchData', this.payloadSla).then(
        res => {
          if (res.data.data === null) {
            this.isNoData = true
          } else {
            this.isNoData = false
          }
        },
        err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },
    exportData(state, shippingValue) {
      const params = {
        startDate: this.payloadSla.startDate,
        endDate: this.payloadSla.endDate,
        shipping: shippingValue === 'J&T' ? 'J%26T' : shippingValue,
        withHoldDuration: state,
      }
      this.$store.dispatch('slaMonitoring/ExportData', params).then(
        res => {
          const { data } = res.data
          const base = data.base64
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base}`
          const downloadLink = document.createElement('a')
          downloadLink.download = data.filename
          downloadLink.href = linkSource
          downloadLink.target = '_blank'
          downloadLink.click()
        },
        err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response,
              variant: 'danger',
            },
          }, 2000)
        },
      )
    },
    async getDaysInMonth(firstMonth, year) {
      /* eslint-disable no-param-reassign */
      year = this.yearDateRange
      firstMonth += 1
      const firstDate = new Date(year, firstMonth - 1)
      const lastDate = new Date(year, firstMonth, 0)
      this.payloadSla.startDate = this.formatterDate(firstDate)
      this.payloadSla.endDate = this.formatterDate(lastDate)
      this.fetchData()
    },
    formatterDate(date) {
      const d = date
      let month = `${(d.getMonth() + 1)}`
      let days = `${d.getDate()}`
      const years = d.getFullYear()

      if (month.length < 2) {
        month = `0${month}`
      }
      if (days.length < 2) {
        days = `0${days}`
      }

      return [years, month, days].join('-')
    },
  },
}
</script>

<style scoped>
.card-body {
  padding-left: 0;
  padding-right: 0;
}
.title-table {
  background: #f4f4f4;
}
.popover {
  background: #f8f8f8 !important;
  max-width: 350px !important;
}
.popover-body {
  padding: none !important;
}
.button-month {
  background: #f4f4f4 !important;
  border: none !important;
  border-radius: 8px !important;
  color: #c2c2c2 !important;
  width: 90%;
  height: 35px;
}
.button-month-disabled {
  background: #f4f4f4 !important;
  border: none !important;
  border-radius: 8px !important;
  color: #c2c2c2 !important;
  width: 90%;
  height: 35px;
}
.button-month:hover {
  background: #f95031 !important;
  color: white !important;
}
.button-month-active {
  background: #f95031 !important;
  color: white !important;
  border: none !important;
  border-radius: 8px !important;
  height: 35px;
  width: 90%;
}
.button-month-past {
  background: white !important;
  color: black !important;
  border: none !important;
  border-radius: 8px !important;
  width: 90%;
  height: 35px;
}
.button-month-past:hover {
  background: #f95031 !important;
  color: white !important;
}

.no-data-class {
  position: absolute;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 2rem;
  margin-left: auto;
}

.custom-popover {
  background: white!important;
  font-family: "Poppins", sans-serif;
}
.custom-popover-hold {
  background: white!important;
  font-family: "Poppins", sans-serif;
}
</style>
